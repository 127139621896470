<template>
  <div>
    <!-- 奖罚单 -->
    <el-row type="flex" justify="space-between">
      <div class="top-info">
        <el-button class="fontsize18" icon="el-icon-arrow-left" size="mini" @click="$router.go(-1)">
          返回
        </el-button>
        <div class="fontsize18 mr-10">姓名：{{ params.name }}</div>
        <div class="fontsize18">岗位：{{ params.postName }}</div>
      </div>
      <div class="datePicker">
        <el-date-picker
          v-model="date"
          type="daterange"
          placeholder="选择日期"
          class="datePicker"
          value-format="yyyy-MM-dd"
          @change="getData"
        >
        </el-date-picker>
      </div>
    </el-row>
    <div class="mt-10">
      <el-table
        header-cell-class-name="table-header"
        cell-class-name="row-cell"
        :data="tableList"
        class="table table-container"
      >
        <el-table-column
          label="序号"
          type="index"
          width="100"
        ></el-table-column>
        <el-table-column
          label="姓名"
          prop="userName"
          width="150"
        ></el-table-column>

        <el-table-column
          label="开具时间"
          prop="createTime"
          width="200"
        ></el-table-column>
        <el-table-column label="奖罚维度" >
          <template slot-scope="scope">
            <span v-if="scope.row.aspect == 1">纪律表现</span>
            <span v-if="scope.row.aspect == 2">管理服从度</span>
            <span v-if="scope.row.aspect == 3">安全文明施工</span>
            <span v-if="scope.row.aspect == 4">效率</span>
            <span v-if="scope.row.aspect == 5">质量</span>
            <span v-if="!scope.row.aspect">--</span>
          </template>
        </el-table-column>
        <el-table-column label="原因" prop="reason">
          <template slot-scope="scope">
            <div
              style="
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
              "
            >
              {{ scope.row.reason }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="奖罚类型" >
          <template slot-scope="scope">
            <span class="color2" v-if="scope.row.type == 1">奖励单</span>
            <span class="color1" v-if="scope.row.type == 2">惩罚单</span>
            <span class="color3" v-if="scope.row.type == 3">其他</span>
          </template>
        </el-table-column>
        <el-table-column label="金额（元）" width="150">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.type == 1
                  ? 'color2'
                  : scope.row.type == 2
                  ? 'color1'
                  : 'color3'
              "
            >
              {{ scope.row.money }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="详情" prop="name" >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="medium"
              @click="handleDetail(scope.row)"
              >点击查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block mt10 pagetion">
        <el-pagination
          layout="total, prev, pager, next"
          :total="pageData.total"
          :page-size="pageData.pageSize"
          :current-page.sync="pageData.pageNum"
          @current-change="getData"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="奖罚单详情"
      :visible.sync="showRegulationDetail"
      width="600px"
      :modal-append-to-body="false"
      destroy-on-close
    >
      <div style="max-height: 50vh; overflow-y: scroll">
        <regulation-detail :dataInfo="regulationDetailData"></regulation-detail>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="
            showRegulationDetail = false;
            regulationDetailData = [];
          "
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import RegulationDetail from "../onstructionData/workManage/regulationDetail.vue";
import moment from "moment"
import { getRewardPunish, getRewardDetail } from "./api.js";
export default {
  components: {
    // RegulationDetail,
  },
  data() {
    return {
      showRegulationDetail: false,
      params: {},
      date: [],
      pageData: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableList: [],
      regulationDetailData: {},
      reFormRecord: [],
    };
  },
  mounted() {
    this.params = this.$route.query;
    var dates = sessionStorage.getItem('dates')
    dates = moment(dates).format("yyyy-MM-DD")
    this.date = [dates, dates]; 
    // this.getData();
    this.calcHeight();
  },
  methods: {
    getData() {
      getRewardPunish({
        projectUserInfoHistoryId: this.params.projectUserInfoHistoryId,
        beginTime: this.date[0],
        endTime: this.date[1],
        ...this.pageData,
      }).then((res) => {
        this.tableList = res.data.rows;
        this.pageData.total = res.data.total;
      });
    },

    handleDetail(row) {
      getRewardDetail({
        regulationId: row.regulationId,
      }).then((res) => {
        this.regulationDetailData = res.data;
        this.showRegulationDetail = true;
      });
    },
    calcHeight() {
      if (this.pageData.pageNum > 1) {
        return;
      }
      const myElement = document.getElementsByClassName("top-info")[0];
      const rect = myElement.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.bottom - 200;
      this.pageData.pageSize = Math.floor(distanceToBottom / 60);
      this.getData();
    },
    handleResize() {
      this.calcHeight();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="less" scoped>
.top-info {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
}
.datePicker ::v-deep .el-input__inner,
.datePicker ::v-deep .el-input__inner .el-range-separator {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}

.datePicker{
  display: flex;
  justify-content: center;
}
.datePicker ::v-deep .el-range-input {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
::v-deep .el-table {
  background: rgba(0, 0, 0, 0);
}
::v-deep .el-table tr {
  background: rgba(0, 0, 0, 0);
}
.color1 {
  color: #ff0000;
}
.color2 {
  color: #0ac200;
}
.color3 {
  color: #fb8400;
}
.mt-10{
  margin-top: 0.1rem;
}
</style>
